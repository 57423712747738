import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  user = new User;
  show_alert : boolean = false;
  alert :IAlert;
  data : Date = new Date();
  focus;
  focus1;
  constructor(private authService: AuthService, private router: Router) { }
  
  ngOnInit() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('sign-up-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
}
  register() {

    this.authService.register(this.user).subscribe(data => {
      console.log('Successfully saved person');
      this.router.navigate(['/index']);
      console.log(this.user.remember_token);
      this.alert = {
        id: 1,
        type: 'success',
        strong: 'Well done!',
        message: 'Successfully saved person.',
        icon: 'ui-2_like'
      }
      this.show_alert = true;
    }, err => {
      if (err instanceof HttpErrorResponse) {
        const errorMessages = new Array<{ propName: string; errors: string }>();

        if (err.status === 422) {
          // TODO: extract errors here and match onto the form
          this.alert = {
            id: 4,
            type: 'danger',
            strong: 'Error',
            message: 'Not uccessfully saved person.',
            icon: 'objects_support-17'
        }
        this.show_alert = true;
        }
      }
    }

    )
    
  }
  public closeAlert() {
    this.show_alert = false;
  } 
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('sign-up-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
}
}
export interface IAlert {
  id: number;
  type: string;
  strong?: string;
  message: string;
  icon?: string;
  color?:string;
}
