export class User{
    id : number;
    name : string;
    email : string;
    email_verified_at : Date;
    role :  string;
    password: string;
    remember_token: string;
    password_confirmation: string;
    code:string;
}