import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

import { User } from 'app/models/User';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  user = new User();
  show_alert: boolean = false;
  alert: IAlert;
  data: Date = new Date();
  focus;
  focus1;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('sign-up-page');
    this.alert = { id: 0, type: '', message: '' };
    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }

  sendResetCode() {
    this.authService.sendResetCode(this.user.email).subscribe(
      (data) => {
        console.log('Successfully sent reset code');
        this.alert = {
          id: 1,
          type: 'success',
          strong: 'Well done!',
          message: 'Reset code sent successfully.',
          icon: 'ui-2_like',
        };
        this.show_alert = true;
        setTimeout(() => {
          this.router.navigate(['/reset-code'], { queryParams: { email: this.user.email } });
        }, 2000); // wait for 2 seconds before navigating to the reset password page
      
        
        
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          const errorMessages = new Array<{ propName: string; errors: string }>();

          if (err.status === 404) {
            // TODO: extract errors here and match onto the form
            this.alert = {
              id: 4,
              type: 'danger',
              strong: 'Error',
              message: 'User not found',
              icon: 'objects_support-17',
            };
            this.show_alert = true;
          }
        }
      }
    );
  }

  public closeAlert() {
    this.show_alert = false;
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('sign-up-page');

    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }
}

export interface IAlert {
  id: number;
  type: string;
  strong?: string;
  message: string;
  icon?: string;
  color?: string;
}
