import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Transaction } from 'app/models/Transaction';
import { TransactionService } from 'app/services/transaction.service';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { User } from 'app/models/User';

@Component({
  selector: 'app-update-transaction',
  templateUrl: './update-transaction.component.html',
  styleUrls: ['./update-transaction.component.scss']
})
export class UpdateTransactionComponent implements OnInit {
  transaction : any;
  id : any;
  selectedFile : any;
  email_valide :boolean;
  email_msg : string;
  constructor(private route: ActivatedRoute, private transactionService: TransactionService, private router:Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getTransactionById(this.id);
  }
  getTransactionById(id){
    this.transactionService.getTransactionById(id).toPromise().then((res : Transaction) => {
       console.log(res); 
       this.transaction = res;
       this.transaction.file = res.file;
        const dateString = this.transaction.echeance;
        const dateRegex = /{year:(\d+),month:(\d+),day:(\d+)}/;
        const [, yearStr, monthStr, dayStr] = dateString.match(dateRegex);
        const year = parseInt(yearStr);
        const month = parseInt(monthStr) - 1;
        const day = parseInt(dayStr);

        this.transaction.echeance = { year, month, day };
        console.log(this.transaction.echeance)
        }); 
}
updateTransaction(){
  this.transactionService.updateStatus2(this.id,this.transaction).subscribe(data => {
    console.log(data);
    this.router.navigate(['/transactions']) ;    
  });
}
onselectFile(e : Event){
   
  var reader = new FileReader();
  reader.readAsDataURL((e.target as HTMLInputElement)?.files?.[0]);
  reader.onload=(event:any)=>{
    this.selectedFile=event.target.result;
    console.log(this.selectedFile);
  }

console.log(this.selectedFile)
}

convertFile(file : File) : Observable<string> {
const result = new ReplaySubject<string>(1);
const reader = new FileReader();
reader.readAsBinaryString(file);
reader.onload = (event) => result.next(btoa(event.target.result.toString()));
return result;
}

verifyEmail(){
  const email = {
    "email" : this.transaction.second_user
  } 
  this.transactionService.verifyEmail(email).subscribe(data => {
    
    console.log(data)
    this.email_valide = true;
    this.email_msg = "Utilisateur valide";
    this.authService.loggedIn().toPromise().then((res : User) => {
      if(this.transaction.second_user == res.email ){
        this.email_valide = false;
        this.email_msg = "c'est votre email";
      }
    })
  }, err => {
    if (err instanceof HttpErrorResponse) {
      const errorMessages = new Array<{ propName: string; errors: string }>();
      if (err.status === 404) {
        this.email_valide = false;
        this.email_msg = "Utilisateur introuvable";
      }
      if (err.status === 405) {
        this.email_valide = false;
        this.email_msg = "Utilisateur introuvable";
      }
    }
  }
  );
 
 
 }
}
