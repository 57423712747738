import { Component, OnInit, ElementRef, OnDestroy, ViewChild, TemplateRef  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Transaction } from 'app/models/Transaction';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';
import { TransactionService } from 'app/services/transaction.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IAlert } from '../add-transaction/add-transaction.component';
import { SousTransaction } from 'app/models/SousTransaction';
import { Router } from '@angular/router';

@Component({
    selector: 'app-nucleoicons',
    templateUrl: './nucleoicons.component.html',
    styleUrls: ['./nucleoicons.component.scss']
})
export class NucleoiconsComponent implements OnInit, OnDestroy {
    transactions : Transaction[];
    soustransactions: SousTransaction[];
    transaction : any;
    isAdmin : boolean;
    email : any;
    page : number;
    closeResult: string;
    simpleSlider = 40;
    doubleSlider = [20, 60];
    me : User;
    form_valide : boolean = false;
    date: {year: number, month: number, day:number};
    show_alert : boolean = false;
    alert :IAlert;
    sous_transaction : SousTransaction = new SousTransaction;
    popup_name : any;
    btn_disabled : boolean;
    statusDemandePaiementValidee: number = 8;
    @ViewChild('add', { static: false }) popup_add: TemplateRef<any>;
    @ViewChild('list', { static: false }) popup_list: TemplateRef<any>;


    constructor( private element : ElementRef, private transactionService: TransactionService,private router:Router,private authService: AuthService, private sanitizer: DomSanitizer, private modalService: NgbModal) {}

    ngOnInit() {
        this.page = 1;
        if(localStorage.getItem("user_role") == "admin"){
            this.isAdmin = true;
        }else{
            this.isAdmin = false;
        }
        this.authService.loggedIn().toPromise().then((res : User) => {
            this.me = res;
            console.log(res.name);
          }).catch(err => {
            console.log(err.statusText);
          });
        this.getTransaction();
        this.btn_disabled = false;
        let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        navbar.classList.remove('navbar-transparent');
    }
    getTransaction(){
        console.log(this.isAdmin)
        if(this.isAdmin){
            this.transactionService.getTransaction().toPromise().then((res : any[]) => {
                this.transactions = res;
                }); 
        }else{
           this.transactionService.getUserTransaction().toPromise().then((res : any[]) => {
            this.transactions = res;
            }); 
        }
    }
    validerStatus(transaction, status){
        transaction.status = status;
       // this.transaction.montant =  montant;
        if(transaction.type == "Acheteur"){
            this.authService.getUserById(transaction.first_user).toPromise().then((res : User) => {
                transaction.send_mail = res.email;
            })
        }else{
            transaction.send_mail = transaction.second_user;
        }
        
        this.transactionService.validerStatus(transaction.id, transaction).subscribe(data => {
            // this.transactionService.sendMail(transaction).subscribe(data => {
            //   });
              this.getTransaction();
          });
    }
   
    changeStatus(transaction, status){
        if (status === this.statusDemandePaiementValidee) {
            if (transaction.type === 'Acheteur') {
              transaction.status = status;
              this.transactionService.updateStatus(transaction.id, transaction).subscribe(data => {
        
                this.getTransaction();
              });
            }
          }
        if(status == 1){
            if(transaction.type == "Vendeur"){
                this.authService.getUserById(transaction.first_user).toPromise().then((res : User) => {
                    transaction.send_mail = res.email;
                })
            }else{
                transaction.send_mail = transaction.second_user;
            }
            // this.transactionService.sendMailVendeur(transaction).subscribe(data => {
            //     console.log(data);qs
            //   });
            
        }
        
        if(status == 4 || status == 5){
            transaction.loggedIn_user = localStorage.getItem("user_id");
        }
        if(status == 2){
            if(transaction.type == "Acheteur"){
                this.authService.getUserById(transaction.first_user).toPromise().then((res : User) => {
                    transaction.send_mail = res.email;
                })
            }else{
                transaction.send_mail = transaction.second_user;
            }
        }
        // if(status == 5){
        //     if(transaction.type == "Vendeur"){
        //         this.authService.getUserById(transaction.first_user).toPromise().then((res : User) => {
        //             transaction.send_mail = res.email;
        //         })
        //     }else{
        //         transaction.send_mail = transaction.second_user;
        //     }
        // }
        transaction.status = status;
        this.transactionService.updateStatus(transaction.id, transaction).subscribe(data => {
            this.getTransaction();
            // if(status == 2){ 
            //     this.transactionService.sendMail(transaction).subscribe(data => {
            //         console.log(data);
            //       });
            // }

            // if(status == 5){ 
            //     this.transactionService.demandeSequestreMail(transaction).subscribe(data => {
            //         console.log(data);
            //       });
            // }
          });
        
    }
    validerDemandePaiement(transaction) {
        transaction.status = this.statusDemandePaiementValidee;
        this.transactionService.updateStatus(transaction.id, transaction).subscribe(data => {
          this.getTransaction();
        });
      }
    transform(value: any, args?: any): any {
        let element = document.createElement('a');
        element.setAttribute('href', value);
        element.setAttribute('download', 'myzip.pdf');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }

      open(content, type, modalDimension, transacion) {
        console.log(content._declarationTContainer.localNames[0], type, modalDimension)
        if(content._declarationTContainer.localNames[0] == 'list'){
            this.getSousTransaction(transacion.id);
        }
        if(content._declarationTContainer.localNames[0] == 'Valider'){
            this.getSousTransaction(transacion.id);
        }
        if(content._declarationTContainer.localNames[0] == 'addpay'){
            this.getSousTransaction(transacion.id);
            this.popup_name = 'addpay';
        }
        if(content._declarationTContainer.localNames[0] == 'addseq'){
            this.getSousTransaction(transacion.id);
            this.popup_name = 'addseq';
        }
        this.transaction = transacion;
        this.sous_transaction = new SousTransaction();
        if (modalDimension === 'sm' && type === 'modal_mini') {
            this.modalService.open(content, { windowClass: 'modal-mini modal-primary', size: 'sm' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else if (modalDimension == undefined && type === 'Login') {
          this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        } else {
            this.modalService.open(content).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }

    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }
   async insertSousTransaction(){
        //this.transaction.echeance = this.transaction.echeance.day+"-"+this.transaction.echeance.month+"-"+this.transaction.echeance.year;
        // if(!this.sous_transaction.montant || !this.sous_transaction.type || !this.sous_transaction.date ){
        //   this.form_valide = false;
        // }else{
        //   this.form_valide = true;
        // }
        
        // if(this.form_valide == true){
        this.sous_transaction.id_transaction = this.transaction.id;
        let date = new Date();
        let trans;
        this.sous_transaction.date = date.toLocaleDateString();        
        if(this.popup_name == 'addpay'){
            this.sous_transaction.type = "paiement";
        }
        if(this.popup_name == 'valider'){
            this.sous_transaction.type = "valider";
        }
        if(this.popup_name == 'addseq'){
            this.sous_transaction.type = "sequestre";
        }
        if(this.sous_transaction.type == "paiement"){
            console.log("before",this.sous_transaction)
           await this.transactionService.getSousTransactionById(Number(this.sous_transaction.seq)).toPromise().then((res : any) => {
            console.log("after",this.sous_transaction)
            trans = res;
             this.sous_transaction.montant = res.montant;
             this.sous_transaction.type = "paiement";
             this.sous_transaction.status = 4;
                }); 
        }else{
            this.sous_transaction.status = 2;
        }
        this.transactionService.insertSousTransaction(this.sous_transaction).subscribe(data => {
            // if(this.transaction.status.id == 1){
            //     this.changeStatus(this.transaction,2);
            // }
            this.btn_disabled = true;
            this.transaction.montant = this.sous_transaction.montant;
            this.changeStatus(this.transaction,this.sous_transaction.status)
            this.getSousTransaction(this.transaction.id)
            this.getTransaction()
            this.modalService.dismissAll(this.popup_add)
            this.modalService.open(this.popup_list)
   
          })
        // }else {
        //   this.alert = {
        //     id: 4,
        //     type: 'danger',
        //     strong: 'Error',
        //     message: 'Vous devez remplir tous les champs obligatoires',
        //     icon: 'objects_support-17'
        // }
        // this.show_alert = true;
        // }
      }
      getSousTransaction(id : any){
        this.transactionService.getSousTransaction(id).toPromise().then((res : any[]) => {
            this.soustransactions = res;
            }); 
    }
    updateSousTransaction(soustransacion, status){
        soustransacion.status = status;
        this.transactionService.updateSousTransaction(soustransacion.id, soustransacion).subscribe(data => {
            this.getSousTransaction(soustransacion.id_transaction);
            this.getTransaction();
          });
    }
    ngOnDestroy(){
        let navbar = document.getElementsByTagName('app-navbar')[0].children[0];

    }
}
