import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';
import { Alert } from 'selenium-webdriver';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  user = new User;
  show_alert : boolean = false;
  alert :IAlert;
  data : Date = new Date();
  focus;
  focus1;
  code:string;
  constructor(private authService: AuthService, private router: Router,private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.user.code = params['code']; // retrieve email from query parameter
      
      // do something with the email
    });
  
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('sign-up-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
}
resetPassword() {

    this.authService.resetPassword(this.user).subscribe(data => {
      console.log('Successfully saved person');
      this.alert = {
        id: 1,
        type: 'success',
        strong: 'Well done!',
        message: 'Password reset successfully.',
        icon: 'ui-2_like'
      }
      this.show_alert = true;
      setTimeout(() => {
        this.router.navigate(['/examples/login']);
      }, 2000); // wait for 2 seconds before navigating to the reset password page
    
      
    
    }, err => {
      if (err instanceof HttpErrorResponse) {
        const errorMessages = new Array<{ propName: string; errors: string }>();

        if (err.status === 400) {
          // TODO: extract errors here and match onto the form
          this.alert = {
            id: 4,
            type: 'danger',
            strong: 'Error',
            message: 'User not found',
            icon: 'Invalid reset code.',
          };
          this.show_alert = true;
        }
        if (err.status === 404) {
          // TODO: extract errors here and match onto the form
          this.alert = {
            id: 4,
            type: 'danger',
            strong: 'Error',
            message: 'User not found.',
            icon: 'objects_support-17',
          };
          this.show_alert = true;
        }
        if (err.status === 402) {
          // TODO: extract errors here and match onto the form
          this.alert = {
            id: 4,
            type: 'danger',
            strong: 'Error',
            message: 'Reset code has expired.',
            icon: 'objects_support-17',
          };
          this.show_alert = true;
        }
      }
    }

    )
    
  }
  public closeAlert() {
    this.show_alert = false;
  } 
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('sign-up-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
}
}
export interface IAlert {
  id: number;
  type: string;
  strong?: string;
  message: string;
  icon?: string;
  color?:string;
}