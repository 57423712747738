import { Component, OnInit } from '@angular/core';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';
import * as Rellax from 'rellax';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  zoom: number = 14;
  lat: number = 44.445248;
  lng: number = 26.099672;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    data : Date = new Date();
    focus;
    focus1;
    user : any;
    edit_pass : boolean = false;
    edit_profile : boolean = true;
    password_request : any;
    show_alert : boolean = false;
    alert :IAlert;
    user_info : any;
    isAdmin : boolean;
    constructor(private authService: AuthService) { }

    ngOnInit() {
      this.edit_profile = true;
      if(localStorage.getItem("user_role") == "admin"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }
      this.authService.loggedIn().toPromise().then((res : User) => {
        this.user = res;
        console.log(res.name);   
      }).catch(err => {
        console.log(err.statusText);
      });
      this.password_request = {
        current_password: "",
        new_password : "",
        new_confirm_password : ""
      };
      this.user_info = {
        email: "",
        name : ""
      };
      var rellaxHeader = new Rellax('.rellax-header');
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('profile-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.add('navbar-transparent');
  }
  editForm(type){
    if(type=="pass"){
      this.edit_pass = true;
      this.edit_profile = false;
    }else{
      this.edit_pass = false;
      this.edit_profile = true;
    }
  }
  changePassword(id){
    console.log(this.password_request)
    this.password_request.current_password = this.password_request.current_password;
    this.password_request.new_password = this.password_request.new_password;
    this.password_request.new_confirm_password = this.password_request.new_confirm_password;
    
  
    this.authService.changePassword(id,this.password_request).toPromise().then(res => {
      this.alert = {
        id: 1,
        type: 'success',
        strong: 'Succès!',
        message: 'Mot de passe enregistré avec succès.',
        icon: 'ui-2_like'
      }
      this.show_alert = true;
    }).catch(err => {
      if (err.status === 422) {
        this.alert = {
          id: 4,
          type: 'danger',
          strong: 'Erreur!',
          message: 'Le mot de passe ne correspond pas',
          icon: 'objects_support-17'
      }
      this.show_alert = true;
    }
    });
  
  }
  updateUserInfo(id){
   this.user_info.email = this.user.email;
   this.user_info.name = this.user.name;
   this.user_info.rib = this.user.rib;


    this.authService.updateUser(id,this.user_info).toPromise().then(res => {
      this.alert = {
        id: 1,
        type: 'success',
        strong: 'Succès!',
        message: 'Enregistré avec succès.',
        icon: 'ui-2_like'
      }
      this.show_alert = true;
    }).catch(err => {
      if (err.status === 422) {
        this.alert = {
          id: 4,
          type: 'danger',
          strong: 'Erreur!',
          message: '',
          icon: 'objects_support-17'
      }
      this.show_alert = true;
    }
    });
  
  }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    public closeAlert() {
      this.show_alert = false;
    } 
  }
  export interface IAlert {
    id: number;
    type: string;
    strong?: string;
    message: string;
    icon?: string;
    color?:string;
  }
