export class Transaction{
    id : number;
    depot : number;
    file : any;
    echeance : string;
    status : number;
    first_user: number;
    second_user: string;
    type: string;
    montant : number;
    send_mail : any;
}