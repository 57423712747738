import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from './cookie.service';
import { AuthStatus } from 'app/models/auth-status.model';
import { User } from 'app/models/User';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  serverUrl = environment.baseUrl;
  private readonly authTokenName = 'XSRF-TOKEN';
  private readonly SessionLogin = 'Session-Login';

  authToken: BehaviorSubject<string> = new BehaviorSubject(null);
  Session: BehaviorSubject<string>;
  authenticated: BehaviorSubject<AuthStatus> = new BehaviorSubject({ authenticated: false, message: '' });

  status: AuthStatus;
  constructor(private http: HttpClient,
    private cookieService: CookieService,) { }

  handleAuthCookie() {
    this.authToken = new BehaviorSubject(this.cookieService.getCookie(this.authTokenName));
    this.Session = new BehaviorSubject(this.cookieService.getCookie(this.SessionLogin));
    this.authToken.subscribe(token => {
      if (token) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getHours() + 1);

        this.cookieService.setCookieForLongPeriod(this.authTokenName, token);
      } else {
        this.cookieService.getCookie(this.authTokenName);
      }
    });
    if (this.authToken.value) {
      this.status = { authenticated: true };
    } else {
      this.status = { authenticated: false, message: 'No token found' };
    }
    this.authenticated = new BehaviorSubject(this.status);
  }


  register(data) {
    console.log('aaa');
    return this.http.post(this.serverUrl + 'api/reg', data);
  }
  resetPassword(data) {
    console.log(data);
    //const obj = { "email": email , "code":code }; 
    console.log('aaa');
    return this.http.post(this.serverUrl + 'api/reset-password', data);
  }
  login(request) {
    const data = {
      email: request.email,
      password : request.password,
    };

    const formBody = [];

    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);

      formBody.push(encodedKey + '=' + encodedValue);
    }

    const formString = formBody.join('&');

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    return this.http.post(this.serverUrl + 'api/log', formString, { headers }).toPromise()
    .then((res : User) => {
      console.log("hello",res)
      const token = this.cookieService.getCookie(this.authTokenName);
      console.log(token)
      localStorage.setItem("laravel_session",token);
      localStorage.setItem("user_id",res.id.toString());
      localStorage.setItem("user_role",res.role);
     

        this.authToken.next(token);

      const status: AuthStatus = {
        authenticated: true
      };
      this.authenticated.next(status);
      return status;
    })
    .catch(err => {
      const error = err.error.error_description;
      const status: AuthStatus = {
        authenticated: false,
        message: error
      };
      this.authenticated.next(status);
      return status;
    });
  }
  sendResetCode(email) {
    console.log('AuthService.sendResetCode() called.');
    const obj = { "email": email }; 
    console.log(obj);
    
    return this.http.post(this.serverUrl+'api/forgot-password',obj);
   
  
   
  }
  verifyResetCode(email,code) {
    console.log('code verified');
    const obj = { "email": email , "code":code }; 
    console.log(obj);
    
    return this.http.post(this.serverUrl+'api/verify-password',obj);
   
  
   
  }
  loggedIn () {
    return this.http.get(this.serverUrl + 'api/infoUser/'+localStorage.getItem("user_id"))
  }
  getUserById (id) {
    return this.http.get(this.serverUrl + 'api/infoUser/'+id)
  }
  logOut() {
    return this.http.post(this.serverUrl + 'api/log-out', '').toPromise();
  }
  changePassword(id,data){
    const formBody = [];

    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);

      formBody.push(encodedKey + '=' + encodedValue);
    }

    const formString = formBody.join('&');

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    return this.http.post(this.serverUrl + 'api/changePassword/'+id, formString, { headers });
  }
  updateUser(id,data){
    const formBody = [];

    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);

      formBody.push(encodedKey + '=' + encodedValue);
    }

    const formString = formBody.join('&');

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    return this.http.put(this.serverUrl + 'api/updateuser/'+id, formString, { headers });
  }

}
