import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { LoginComponent } from './examples/login/login.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { SignUpComponent } from './examples/sign-up/sign-up.component';
import { AuthGuard } from './services/auth.guard';
import { AddTransactionComponent } from './components/add-transaction/add-transaction.component';
import { SousTransactionComponent } from './components/sous-transaction/sous-transaction.component';
import { BasicelementsComponent } from './components/basicelements/basicelements.component';
import { UpdateTransactionComponent } from './components/update-transaction/update-transaction.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetCodeComponent } from './components/reset-code/reset-code.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { PolitiqueComponent } from './politique/politique.component';
import { CgvCguComponent } from './cgv-cgu/cgv-cgu.component';

const routes: Routes =[
    { path: '', redirectTo: 'index', pathMatch: 'full' },
   
    { path: 'index',                component: ComponentsComponent },
    { path: 'transactions',          component: NucleoiconsComponent  ,canActivate : [AuthGuard]},
    { path: 'sous-transactions',          component: SousTransactionComponent  ,canActivate : [AuthGuard]},
    { path: 'add/transaction',          component: AddTransactionComponent ,canActivate : [AuthGuard] },
    { path: 'update-transaction/:id',          component: UpdateTransactionComponent ,canActivate : [AuthGuard] },

    
    // { path: 'examples/landing',     component: LandingComponent },
    { path: 'examples/login',       component: LoginComponent },
    { path: 'forgot-password', component: ForgetPasswordComponent },
    { path: 'examples/sign-up',       component: SignUpComponent },
    { path: 'reset-code', component: ResetCodeComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'examples/profile',     component: ProfileComponent ,canActivate : [AuthGuard]},
    { path: 'mentions-legales', component: MentionsLegalesComponent },
    { path: 'politique-confidentialite', component: PolitiqueComponent },
    { path: 'cgv-cgu', component: CgvCguComponent },

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
