import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    data : Date = new Date();
    focus;
    focus1;
    user = new User;
    show_alert : boolean = false;
    alert :IAlert;

    constructor(private authService: AuthService,  private router:Router) { }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    login(){
        console.log(this.user)
        this.authService.login(this.user).then((data : any) => {
            console.log(data)
       
       // sessionStorage.setItem('token',data);
       if(data.authenticated !== false){
            console.log(' client valide  ');  
            this.alert = {
                id: 1,
                type: 'success',
                strong: 'Well done!',
                message: '',
                icon: 'ui-2_like'
            }
            this.show_alert = true;
            this.router.navigate(['/transactions']).then(() => {
                window.location.reload();
            });
        }else{
            console.log(' client invalide');
            this.alert = {
                id: 4,
                type: 'danger',
                strong: '',
                message: 'Certaines de vos informations sont incorrectes. Réessayez.',
                icon: 'objects_support-17'
            }
            this.show_alert = true;
        }
        },
        err => {
        if (err instanceof HttpErrorResponse) {
            const errorMessages = new Array<{ propName: string; errors: string }>();
    
            if (err.status === 422) {
            // TODO: extract errors here and match onto the form
            console.log(' client invalide');
            }
        }
    })
   
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    public closeAlert() {
        this.show_alert = false;
    } 
}
export interface IAlert {
    id: number;
    type: string;
    strong?: string;
    message: string;
    icon?: string;
    color?:string;
}
