import { Component, ElementRef, OnInit, Output } from '@angular/core';
import { TransactionService } from 'app/services/transaction.service';
import { Transaction } from 'app/models/Transaction';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';


@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent implements OnInit {

  selectedFile : any;
  transaction : Transaction = new Transaction;
  email_valide :boolean;
  email_msg : string;
  form_valide : boolean = false;
  date: {year: number, month: number, day:number};
  show_alert : boolean = false;
    alert :IAlert;
  constructor(public transactionService : TransactionService, private http: HttpClient,private router:Router,private formBuilder: FormBuilder, private authService: AuthService) { 
  }

  ngOnInit() {
 
  }

  onselectFile(e : Event){
   
      var reader = new FileReader();
      reader.readAsDataURL((e.target as HTMLInputElement)?.files?.[0]);
      reader.onload=(event:any)=>{
        this.selectedFile=event.target.result;
        console.log(this.selectedFile);
      }
    
    console.log(this.selectedFile)
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

   insertData(){
    //this.transaction.echeance = this.transaction.echeance.day+"-"+this.transaction.echeance.month+"-"+this.transaction.echeance.year;
    if(!this.transaction.depot || !this.transaction.type || !this.transaction.echeance ){
      this.form_valide = false;
    }else{
      this.form_valide = true;
    }
    this.transaction.file = this.selectedFile;
    this.transaction.status = 1;
    this.transaction.first_user =parseInt(localStorage.getItem("user_id"));
    if(this.form_valide == true && this.email_valide == true){
      this.transactionService.insertTransaction(this.transaction).subscribe(data => {

        console.log(data)
let email_vendeur ;
let email_acheteur ;
          if(this.transaction.type == "Vendeur"){
              this.authService.getUserById(this.transaction.first_user).toPromise().then((res : User) => {
                //this.transaction.send_mail = res.email;
                email_vendeur = res.email;
                email_acheteur = this.transaction.second_user;
              })
          }else{
            email_vendeur = this.transaction.second_user;
            this.authService.getUserById(this.transaction.first_user).toPromise().then((res : User) => {
            email_acheteur = res.email;})
            //this.transaction.send_mail = this.transaction.second_user;
          }
          this.transaction.send_mail = email_vendeur;
          //  this.transactionService.sendMailVendeur(this.transaction).subscribe(data => {
          //   console.log(data);
          //   this.transaction.send_mail = email_acheteur;
             
          //    this.transactionService.sendPremierMail(this.transaction).subscribe(data => {});
          //   });
          
        this.alert = {
          id: 1,
          type: 'success',
          strong: 'Well done!',
          message: '',
          icon: 'ui-2_like'
        }
      this.show_alert = true;
      this.router.navigate(['/transactions']) ;       
      })
    }else {
      this.alert = {
        id: 4,
        type: 'danger',
        strong: 'Error',
        message: 'Vous devez remplir tous les champs obligatoires',
        icon: 'objects_support-17'
    }
    this.show_alert = true;
    }
  }

 verifyEmail(){
  const email = {
    "email" : this.transaction.second_user
  } 
  this.transactionService.verifyEmail(email).subscribe(data => {
    
    console.log(data)
    this.email_valide = true;
    this.email_msg = "Utilisateur valide";
    this.authService.loggedIn().toPromise().then((res : User) => {
      if(this.transaction.second_user == res.email ){
        this.email_valide = false;
        this.email_msg = "c'est votre email";
      }
    })
  }, err => {
    if (err instanceof HttpErrorResponse) {
      const errorMessages = new Array<{ propName: string; errors: string }>();
      if (err.status === 404) {
        this.email_valide = false;
        this.email_msg = "Utilisateur introuvable";
      }
      if (err.status === 405) {
        this.email_valide = false;
        this.email_msg = "Utilisateur introuvable";
      }
    }
  }
  );
 
 
 }

 public closeAlert() {
  this.show_alert = false;
  } 
}
export interface IAlert {
  id: number;
  type: string;
  strong?: string;
  message: string;
  icon?: string;
  color?:string;
}