import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sous-transaction',
    templateUrl: './sous-transaction.component.html',
    styleUrls: ['./sous-transaction.component.scss']
})
export class SousTransactionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
