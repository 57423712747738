import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  serverUrl = environment.baseUrl;
  constructor(private http: HttpClient,) { }

  insertTransaction(data){
    return this.http.post(this.serverUrl+'api/addTransaction',data);
  }
  verifyEmail(data){
    return this.http.post(this.serverUrl+'api/verifyEmail',data);
  }
  getTransaction(){
    return this.http.get(this.serverUrl+'api/transactions');
  }
  getUserTransaction(){
    return this.http.get(this.serverUrl+'api/transactionByuser/'+localStorage.getItem("user_id"));
  }
  updateStatus(id,data){
    return this.http.put(this.serverUrl+'api/updateTransaction/'+id,data);
  }
  updateStatus2(id,data){
    return this.http.put(this.serverUrl+'api/updateTransaction2/'+id,data);
  }
  validerStatus(id,data){
    return this.http.put(this.serverUrl+'api/validerTransaction/'+id,data);
  }
  sendMail(data){
    return this.http.post(this.serverUrl+'api/sendMail',data);
  }
  sendMailVendeur(data){
    return this.http.post(this.serverUrl+'api/sendMailVendeur',data);
  }
  sendPremierMail(data){
    return this.http.post(this.serverUrl+'api/sendPremierMail',data);
  }
  demandeSequestreMail(data){
    return this.http.post(this.serverUrl+'api/demandeSequestreMail',data);
  }
  insertSousTransaction(data){
    return this.http.post(this.serverUrl+'api/addSousTransaction',data);
  }
  getSousTransaction(id){
    return this.http.get(this.serverUrl+'api/soustransaction/'+id);
  }
  updateSousTransaction(id,data){
    return this.http.put(this.serverUrl+'api/updateSousTransaction/'+id,data);
  }
  getSousTransactionById(id){
    return this.http.get(this.serverUrl+'api/getSousTransactionById/'+id);
  }
  getTransactionById(id){
    return this.http.get(this.serverUrl+'api/transaction/'+id);
  }
}
