import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { User } from 'app/models/User';
import { AuthService } from 'app/services/auth.service';
import { CookieService } from 'app/services/cookie.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    me : User;
    loggedIn : boolean;
    index : boolean;
    constructor(public location: Location, private element : ElementRef, private authService: AuthService,private cookieService: CookieService, private router: Router,private route:ActivatedRoute) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.authService.loggedIn().toPromise().then((res : User) => {
            this.me = res;
            console.log(res.name);
            this.loggedIn = true;
          }).catch(err => {
            console.log(err.statusText);
            this.loggedIn = false;
          });
          this.index = window.location.href.includes('index');
    }
    logout() {
        this.cookieService.removeCookie("laravel_session");
        localStorage.clear();
        location.reload();
        this.router.navigate(['/index']).then(() => {
            window.location.reload();
          });
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
  
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}
